body {
  font-family: 'Inter', sans-serif;
}
.header_links{
  list-style: none;
  display: flex;
  justify-content: end;
  gap: 40px;
}
.coming-soon {
background: #18113C;
background-image: url("/public/img/spotlight2.png");
background-repeat: no-repeat;
background-position: center;
background-size: cover;
}

.coming-soon-text p {
  color: #FFFFFF;
  opacity: 0.56;
}
.coming-soon-text h1 span {
color: #754ffe;
}
.coming-soon-text {
margin-bottom: 50px;
margin-top: 60px;
}
div.my-auto.coming_soon {
margin-bottom: 0px !important;
}
.coming_soon h1.page-title.text-white.text-center {
font-weight: 600;
}
.container.bg_image{
max-width: 94% !important;
width: 100%;
margin-left: 2rem;
margin-right: 2rem;
}
input.needsclick.go2668061047.kl-private-reset-css-Xuajs1 {
background-color: rgb(255 255 255/ 10%) !important;
} 
button.needsclick.go1803198139.kl-private-reset-css-Xuajs1 {
padding: 0px 20px !important;
}
button.needsclick.go4272046365.kl-private-reset-css-Xuajs1 {
padding: 0px 20px !important;
}
@media screen and (min-width:769px){
.mobile_img, .mobile_form{
display: none;
}
.form_container {
max-width: 550px;
margin: auto;
padding: 10px 0px 30px;
}
.coming-soon-text p {
max-width: 634px;
margin: auto;
}
.coming-soon-text {
margin-bottom: 0px;
}
}

@media screen and (max-width:768px){
div.my-auto.coming_soon {
margin-top: 0px !important;
}
.coming-soon-text {
margin-bottom: 25px;
margin-top: 25px;
}
.coming_soon h1.page-title.text-white.text-center {
font-size: 32px;
}
.desktop_img, .desktop_form{
display: none;
}
.container.bg_image {
margin: auto;
}
.bg_image img{
  width: 100%;
}
.form_container {
max-width: 90%;
margin: auto;
padding-bottom: 10px;
}
.coming-soon-text {
margin-bottom: -17px !important;
}
.container p {
padding-top: 5px;
font-size: 15px;
}
}

/* privacy_policy */

.container{
  max-width: 1040px !important;
}
.privacy_content, .termandcondition_content{
  max-width: 900px;
  width: 900px;
  margin: auto;
}

.privacy_bg{
  background-color: #fff;
}